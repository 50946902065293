<template>
  <FocusTrap>
    <div class="card" id="journal_card">

    <div class="card-header header-elements-inline"  style="background-color: whitesmoke; padding: 10px;" >
      <h5 v-if="voucher.id == 0" class="card-title">Journal Creation</h5>
      <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.doc_no}} - Journal Update</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
          <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
        </div>
      </div>
    </div>


    <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <!--<div class="form-group form-group-material">-->
            <!--<label class="control-label text-semibold">Paid Ledger</label>-->
            <!--<select id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher" v-model="voucher.ledger.id" >-->
              <!--<option v-for="ledger in ledgers" v-bind:value="ledger.id">-->
                <!--{{ ledger.name }}-->
              <!--</option>-->
            <!--</select>-->
          <!--</div>-->
        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input ref="doc_date" id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date" autofocus>
          </div>
        </div>
      </div>


      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th style="width: 30px;text-align: left;">Cr/Dr</th>
              <th >Particulars</th>
              <th >Description</th>
              <th style="width:150px; text-align: right;">Credit</th>
              <th style="width:150px; text-align: right;">Debit</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody v-for="(detail,index) in voucher.list">
              <JournalDetailRow  v-if="detail" :ledgers="ledgers" :index="index" :myrow="detail" v-on:onrow_change="sumRowAmountTotal" v-on:onRemoveRow="removeRow" ></JournalDetailRow>

              <!--<tr v-if="voucher" v-for="(detail,index) in voucher.list" >-->
              <!--<td style="text-align: center;padding: 0px;"> {{index + 1}} </td>-->
              <!--<td style="padding: 0px;">-->
                <!--<select class="form-control"  >-->
                  <!--<option value="cr">Cr</option>-->
                  <!--<option value="dr">Dr</option>-->
                <!--</select>-->
              <!--</td>-->
              <!--<td style="padding: 0px;">-->
                <!--<select class="form-control" v-if="detail" v-model="detail.ledger.id" >-->
                  <!--<option v-for="ledger in ledgers" v-bind:value="ledger.id">-->
                    <!--{{ ledger.name }}-->
                  <!--</option>-->
                <!--</select>-->
              <!--</td>-->
              <!--<td style="padding: 0px;">-->
                <!--<input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.remarks" />-->
              <!--</td>-->
              <!--<td style="padding: 0px;">-->
                <!--<input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.credit" @change="sumRowAmountTotal" />-->
              <!--</td>-->
              <!--<td style="padding: 0px;">-->
                <!--<input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.debit" @change="sumRowAmountTotal" />-->
              <!--</td>-->
              <!--<td style="padding: 0px;text-align: right;">-->
                <!--<button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>-->
              <!--</td>-->
            <!--</tr>-->

            </tbody>
            <tfoot>
            <tr>
              <td style="padding: 0px;">
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold text-right" >&#8377;<span style="padding-right: 0px">{{ indianFormat(creditTotal) }}</span></td>
              <td class="font-weight-bold text-right" >&#8377;<span style="padding-right: 0px">{{ indianFormat(debitTotal) }}</span></td>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">
        </div>

        <div class="col-md-3 text-right">
          <SaveButton title="Save" @handle_save_action="saveVoucher" style="margin: 1em;"></SaveButton>
          <CloseButton title="Clear" @handle_close_action="clear" ></CloseButton>
        </div>

      </div>
    </div>


  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import JournalDetailRow from '@/components/core/JournalDetailRow.vue'
  import CloseButton from '../../../components/core/buttons/CloseButton'
  import SaveButton from '../../../components/core/buttons/SaveButton'

  export default {
    name: 'JournalForm',
    store,
    components:{
      SaveButton,
      CloseButton,
      JournalDetailRow
    },
    data () {
      return {
        readonly: false,
        ledgers:[],
        creditTotal: 0.0,
        debitTotal: 0.0,
        voucher: JSON.parse('{"id":0,"status":"A","type":0,"vch_no":1,"finyear":2000,"vch_type":1,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","cmp_id":1,"ledger":{"id":1,"name":""},"list":[]}')
      }
    },
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"A","type":0,"vch_no":1,"finyear":2000,"vch_type":1,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","cmp_id":1,"ledger":{"id":1,"name":""},"list":[]}')
      }
    },
    beforeMount () {
      this.voucher = this.myvoucher;
      this.sumRowAmountTotal();
    },
    created () {
      this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      this.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      const self = this;
      self.loadData();

      if( self.$data.voucher.doc_date == '0001-01-01' || self.$data.voucher.doc_date == '2000-01-01' ){
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      }
      $('#txtDate').focus();
      self.addRow();
      self.$refs.doc_date.focus();
    },
    methods:{

      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      closeThis(){
        this.$emit('journal_window_closed');
        if(this.voucher.id > 1){
          this.$modal.hide('voucher-window');
          this.voucher = JSON.parse('{"id":0,"status":"A","type":0,"vch_no":1,"finyear":2000,"vch_type":1,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","cmp_id":1,"ledger":{"id":1,"name":""},"list":[]}');
        }else{
          this.$router.push("/")
        }

      },
      addRow(){
        const self = this;
        this.$data.voucher.list.push(JSON.parse('{"id":0,"voucher_id":0,"idx":0,"adjust_type":0,"ledger_id":0,"debit":0,"credit":0,"remarks":"","ledger":{"id":0,"group_id":0,"name":"","alias":"","open_bal":0}}'));
        setTimeout(function(){
          $("tbody>tr").last().find("td:eq(1) select").focus();
        }, 99);
      },
      sumRowAmountTotal() {
        const self = this;

        self.$data.debitTotal = 0.0;
        self.$data.creditTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.creditTotal += parseFloat(detail.credit);
          self.$data.debitTotal += parseFloat(detail.debit);
        });
      },
      clear(){
        const self = this;
        try {
          self.$data.voucher = JSON.parse('{"id":0,"status":"A","type":0,"vch_no":1,"finyear":2000,"vch_type":1,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","cmp_id":1,"ledger":{"id":1,"name":""},"list":[]}');
          self.$data.voucher.list = [];
          self.$data.debitTotal = 0.00;
          self.$data.creditTotal = 0.00;
          self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
          self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
          setTimeout(function(){
            self.addRow();
          }, 99);

        }catch (e) {
          alert(e);
        }

      },
      loadData() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/ledgers/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      saveVoucher(){
        const self = this;

        try {

          console.log(JSON.stringify(self.$data.voucher));

          let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));

          myvoucher.finyear = self.$store.state.user.finyear;
          myvoucher.cmp_id = self.$store.state.user.company.id;
          myvoucher.brn_id = self.$store.state.user.branch.id;
          myvoucher.vch_type = 103;
          myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
          myvoucher.ref_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
          myvoucher.amount = parseFloat(self.$data.rowAmountTotal);

          let crTotal = 0;
          let drTotal = 0;
          myvoucher.list.forEach(function (detail) {
            detail.ledger_id = parseInt(detail.ledger_id);
            detail.debit = parseFloat(detail.debit);
            detail.credit = parseFloat(detail.credit);

            if (detail.ledger_id < 2) {
              alert("Invalid Ledger");
              return;
            }

            crTotal += detail.debit;
            drTotal += detail.credit;
          });
          myvoucher.amount = drTotal;

          if (myvoucher.amount < 0) {
            alert("Invalid Vocuher Amount");
            return;
          } else if (crTotal != drTotal) {
            alert("Invalid Credit or Debit Amount");
            return;
          }

          const requestOptions = {
            method: ((myvoucher.id == 0) ? 'POST' : 'PUT'),
            mode: 'cors',
            headers: userService.authHeader(),
            body: JSON.stringify(myvoucher)
          };

          console.log(JSON.stringify(myvoucher));

          fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/voucher`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok) {

              self.clear();
              self.$emit('journal_saved');
              swal({
                text: resp.msg, type: 'success', onClose: () => {
                  $('#txtDate').focus()
                }, timer: 1500
              });

              self.$refs.doc_date.focus();
            } else {
              swal({
                title: "Oops", text: resp.msg, type: "error", onClose: () => {
                  $('#txtDate').focus()
                }, timer: 3000
              });
            }
          }).catch(function (err) {
            swal({
              title: "Oops", text: err.toString(), type: "error", onClose: () => {
                $('#txtDate').focus()
              }, timer: 3000
            });
          });

        }catch (e) {
          alert(e);
        }

      }
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
